import React from 'react'
import { ApolloProvider } from '@apollo/client';
import { createCache, createClient } from 'utils/apollo';

import ThemeProvider from 'providers/ThemeProvider';

import Auth from './Auth';

import AppStyle from 'styles';
import ErrorBoundary from 'components/ErrorBoundary';

const AuthApp = (props) => {
   // use the status here to redirect the user to the payment step or the onboarding step(public page url selection)
   // status can be: 'creating', 'paying', 'onboarding'
   // console.log(props)
   // cool, it works.

   return (
      <ApolloProvider client={createClient(createCache())}>
         <ThemeProvider>
            <AppStyle />
            <ErrorBoundary>
               <Auth {...props} />
            </ErrorBoundary>
         </ThemeProvider>
      </ApolloProvider >
   )
};

export default AuthApp;

