import styled from 'styled-components';
import { PageCard } from 'atoms';

const Alert = styled(PageCard)`
    padding: 20px 20px;
    margin-bottom: 20px;
`;

Alert.defaultProps = {
    shadow: true
};

export default Alert;
