import React from 'react';
import * as Sentry from '@sentry/browser';
import { Integrations } from "@sentry/tracing";

import ReactOnRails from 'react-on-rails';
import Auth from '../src/apps/Auth';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: "https://5d1f9fcda83540b89124635f0b402571@o912642.ingest.sentry.io/5853672",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactOnRails.register({
   Auth
});
