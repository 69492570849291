import React, { useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';

import CreateAccountFromInvitation from 'apps/Auth/components/CreateAccountFromInvitation';
import LogoWide from 'assets/icons/podinbox_logo_wide.png'
import { Card, PageTitle } from 'atoms';
import { LogoContainer, Logo, CardInner } from 'apps/Auth/atoms';

const SignupContainer = styled.div`
`;


const SignupFromInvitation = ({ invitedUserEmail, invitedUserKey }) => {

    const gotoDashboard = () => {
        window.location.replace('/');
    }

    const handleCreateAccount = ({ email }) => {
        gotoDashboard();
    };

    return (
        <SignupContainer>
            <LogoContainer>
                <Logo src={LogoWide} />
            </LogoContainer>
            <PageTitle css={'margin-bottom: 20px;'}>Sign Up & Join Team</PageTitle>
            <Card shadow css={`padding: 20px 0;`}>
                <CardInner>
                    <CreateAccountFromInvitation onCreateAccount={handleCreateAccount} invitedUserEmail={invitedUserEmail} invitedUserKey={invitedUserKey} />
                </CardInner>
            </Card>
        </SignupContainer>
    )
};

export default withTheme(SignupFromInvitation);
