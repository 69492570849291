import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD_WITH_TOKEN_MUTATION } from 'apps/Auth/graphql';

import { passwordChecker } from 'ai';
import { Form } from 'antd';

import { FormItem, Input, Button, Password } from 'atoms';
import { CardInner } from 'apps/Auth/atoms';

const ResetPasswordForm = ({
   token,
   onSuccess,
   onError
}) => {

   const [password, setPassword] = useState('');

   const [triggerResetPassword, { loading: isMutationBusy }] = useMutation(RESET_PASSWORD_WITH_TOKEN_MUTATION, {
      onCompleted({ resetPasswordWithToken: { success, error } }) {
         if (!error) {
            onSuccess();
         } else {
            onError();
         }
      },
      onError() {
      }
   });

   const onFinish = ({
      password
   }) => {
      triggerResetPassword({
         variables: {
            password,
            token
         }
      });
   };

   const [form] = Form.useForm();

   return (
      <Form
         form={form}
         layout="vertical"
         requiredMark={false}
         onFinish={onFinish}
         initialValues={{
            password: '',
            confirmPassword: ''
         }}
      >
         <CardInner>
            <FormItem
               name={'password'}
               label={'New Password'}
               rules={[
                  { required: true, message: 'Password is required' },
                  {
                     validator: (_, value) => {
                        const result = passwordChecker(value);
                        if (result === true) {
                           return Promise.resolve();
                        } else if (result && value) {
                           return Promise.reject(result)
                        } else {
                           return Promise.resolve();
                        }
                     }
                  }
               ]}
            >
               <Password onChange={e => setPassword(e.target.value)} />
            </FormItem>
            <FormItem
               name={'confirmPassword'}
               label={'Confirm New Password'}
               rules={[
                  {
                     validator(_, value) {
                        if (value === password) {
                           return Promise.resolve();
                        } else {
                           return Promise.reject('Password are not matched');
                        }
                     }
                  }
               ]}
            >
               <Password />
            </FormItem>

            <Button type="primary" htmlType="submit" loading={isMutationBusy} block style={{ marginTop: '10px' }}>
               RESET PASSWORD
            </Button>
         </CardInner>
      </Form>
   );
};

export default ResetPasswordForm;
