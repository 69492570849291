import React from 'react';
import styled from 'styled-components';

import { PageTitle, PageCard, Link } from 'atoms';
import { CardInner } from 'apps/Auth/atoms';

import CreateAccount from 'apps/Auth/components/CreateAccount';

const StyledCreateAccount = styled.div`

`;

const SignupCard = styled(PageCard)`
  padding: 20px 0;
`;

const AlreadyHaveContainer = styled.div`
  padding: 30px 0;
  text-align: center;
`;

const LoginLink = styled(Link)`
  color: var(--color-primary);

  :hover {
    color: var(--color-primary);
    opacity: .8;
  }
`;

const CreateAccountStep = (
  {
    onCreateAccount
  }) => {
  return (
    <StyledCreateAccount>
      <PageTitle css={'margin-bottom: 20px;'}>sign up</PageTitle>
      <SignupCard shadow>
        <CardInner>
          <CreateAccount onCreateAccount={onCreateAccount}/>
        </CardInner>
      </SignupCard>
      <AlreadyHaveContainer>
        Already have an account? <LoginLink to={'/login'}>Login</LoginLink>
      </AlreadyHaveContainer>
    </StyledCreateAccount>
  )
};

export default CreateAccountStep;
