import React from 'react';
import styled from 'styled-components';

import { Link, PageTitle, PageCard } from 'atoms';
import { LogoContainer, Logo, CardInner } from 'apps/Auth/atoms';
import LogoWide from 'assets/icons/podinbox_logo_wide.png'

import LoginForm from 'apps/Auth/components/LoginForm';

const LoginContainer = styled.div`
`;

const LoginCard = styled(PageCard)`
   margin-bottom: 40px;
   padding: 20px 0;
`;

const BottomText = styled.div`
   margin-top: 10px;
   text-align: center;
`;

const Login = () => {
   const onLogin = () => {
      window.location.replace('/');
   };

   return (
      <LoginContainer>
         <LogoContainer>
            <Logo src={LogoWide} />
         </LogoContainer>
         <PageTitle>Login</PageTitle>
         <LoginCard shadow>
            <CardInner>
               <LoginForm onLogin={onLogin} />
            </CardInner>
         </LoginCard>
         <BottomText>
            forgot your password? <Link to={'/forgot-password'} primary>Reset Password</Link>
         </BottomText>
         <BottomText>
            need an account? <Link to={'/signup'} primary>Sign Up</Link>
         </BottomText>
      </LoginContainer>
   )
};

export default Login;
