import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/client';

import { passwordChecker } from 'ai';

import { MEMBER_SIGNUP_MUTATION } from 'apps/Auth/graphql';
import { Form, Checkbox } from 'antd';
import { PageCard, Input, FormItem, Button, Link, Password } from 'atoms';

const TermsOfServiceContainer = styled.div`
   padding: 10px 0px;
`;

const TermsLink = styled(Link)`
   color: var(--color-primary);
   :hover {
      color: var(--color-primary);
      opacity: .8;
   }
`;

const TermsCheckboxText = styled.span`
   font-size: 13px;
`;

const [
   EMAIL_ALREADY_EXISTS_ERROR
] = ['Validation failed: Email has already been taken'];

const CreateAccount = ({
   invitedUserEmail,
   invitedUserKey,
   onCreateAccount
}) => {
   const [form] = Form.useForm();
   const [error, setError] = useState(null);

   const [triggerCreateAccount, { loading: isCreating }] = useMutation(MEMBER_SIGNUP_MUTATION, {
      onCompleted({ memberSignUp: { error, success } }) {
         if (!error) {
            onCreateAccount({});
         }
         else {
            handleErrorResponse(error);
         }
      },
      onError(error) {
         console.error("detected Error", error);
      }
   });

   const handleErrorResponse = (error) => {
      switch (error) {
         case EMAIL_ALREADY_EXISTS_ERROR:
            setError(true);
            form.validateFields(['email']);
            break;
         default:
            break;
      }
   }

   const onFinish = ({ email, password, firstName, lastName }) => {
      triggerCreateAccount({
         variables: {
            email,
            firstName,
            lastName,
            password,
            invitedUserKey
         }
      });
   };

   return (
      <Form
         form={form}
         layout="vertical"
         requiredMark={false}
         onFinish={onFinish}
         initialValues={{
            email: invitedUserEmail,
            firstName: '',
            lastName: '',
            password: ''
         }}
      >
         <FormItem
            name={'email'}
            rules={[{ required: true, message: 'Please input your email' }, {
               type: 'email',
               message: 'The input is not valid E-mail',
            }, {
               validator: () => {
                  if (error) {
                     return Promise.reject('Email has already been taken.');
                  } else {
                     return Promise.resolve();
                  }
               }
            }]}
         >
            <Input placeholder={'Email'} readOnly/>
         </FormItem>
         <FormItem
            name={'firstName'}
            rules={[{ required: true, message: 'Please input first name' }, {
               pattern: /^[a-zA-Z0-9\s]+$/,
               message: 'First name must contain only letters, numbers and spaces'
            }]}
         >
            <Input placeholder={'First Name'} />
         </FormItem>
         <FormItem
            name={'lastName'}
            rules={[{ required: true, message: 'Please input last name' }, {
               pattern: /^[a-zA-Z0-9\s]+$/,
               message: 'Last name must contain only letters, numbers and spaces'
            }]}
         >
            <Input placeholder={'Last Name'} />
         </FormItem>
         <FormItem
            name={'password'}
            rules={[{ required: true, message: 'Please input your password' }, {
               validator: (_, value) => {
                  const result = passwordChecker(value);
                  if (result === true) {
                     return Promise.resolve();
                  } else if (result && value) {
                     return Promise.reject(result)
                  } else {
                     return Promise.resolve();
                  }
               },
            }]}
            hasFeedback
         >
            <Password placeholder={'Password'} />
         </FormItem>
         <TermsOfServiceContainer>
            <FormItem
               name={'agreement'}
               valuePropName="checked"
               rules={[{
                  validator: (_, value) =>
                     value ? Promise.resolve() : Promise.reject(new Error('Please accept the agreement')),
               }]}
            >
               <Checkbox>
                  <TermsCheckboxText>
                     I agree to the <TermsLink to={'/'}>terms of use</TermsLink> and <TermsLink to={'/'}>privacy policy</TermsLink>
                  </TermsCheckboxText>
               </Checkbox>
            </FormItem>
         </TermsOfServiceContainer>
         <Button type="primary" htmlType="submit" loading={isCreating} block>
            CREATE ACCOUNT
         </Button>
      </Form>
   );
};

export default CreateAccount;
