import React from 'react';
import styled, { withTheme } from 'styled-components';
import { LogoContainer, Logo } from 'apps/Auth/atoms';
import CreateAccountStep from './components/CreateAccountStep';
import LogoWide from 'assets/icons/podinbox_logo_wide.png'

const SignupContainer = styled.div`
`;

const Signup = ({}) => {
  const gotoDashboard = () => {
    window.location.replace('/');
  }

  const onCreateAccount = ({email}) => {
    gotoDashboard();
  };

  const StepComponent = () => {
    return (
      <CreateAccountStep onCreateAccount={onCreateAccount}/>
    );
  };

  return (
    <SignupContainer>
      <LogoContainer>
        <Logo src={LogoWide}/>
      </LogoContainer>
      <StepComponent/>
    </SignupContainer>
  )
};

export default withTheme(Signup);
