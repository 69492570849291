import React from 'react';
import styled, { withTheme } from 'styled-components';

import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Route, Switch } from 'react-router-dom';

import Signup from './pages/Signup';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import SignupFromInvitation from './pages/SignupFromInvitation';

const AppWrapper = styled.div`

`;

const AuthContainer = styled.div`
   max-width: 400px;
   margin: 0 auto;
   padding: 100px 15px 50px;
`;

const history = createBrowserHistory();

const Auth = ({
   status,
   invited_user_email,
   invited_user_key,
   prices
}) => {
   return (
      <Router history={history}>
         <AppWrapper>
            <AuthContainer>
               <Switch>
                  <Route path={'/signup/:type?'}>
                     <Signup status={status} prices={prices}/>
                  </Route>
                  <Route path={'/invitation'}>
                     <SignupFromInvitation invitedUserEmail={invited_user_email} invitedUserKey={invited_user_key} />
                  </Route>
                  <Route path={'/login'}>
                     <Login />
                  </Route>
                  <Route path={'/forgot-password'}>
                     <ForgotPassword />
                  </Route>
                  <Route path={'/reset-password'}>
                     <ResetPassword />
                  </Route>
               </Switch>
            </AuthContainer>
         </AppWrapper>
      </Router>
   )
};

export default withTheme(Auth);
