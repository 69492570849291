import React, { useState } from 'react';
import styled from 'styled-components';

import { useURLQuery } from 'hooks';

import { Link, PageTitle, message } from 'atoms';
import { LogoContainer, Logo, CardInner, PageCard, Alert } from 'apps/Auth/atoms';
import LogoWide from 'assets/icons/podinbox_logo_wide.png'

import ResetPasswordForm from 'apps/Auth/components/ResetPasswordForm';

const ResetPasswordContainer = styled.div`
`;

const ResetPassword = () => {
   const [error, setError] = useState(null);
   const onSuccess = () => {
      setError(false);
      message.success({ content: 'Your password was changed successfully!' });
      setTimeout(() => {
         window.location.href = '/';
      }, 1000);
   };

   const { reset_password_token: token } = useURLQuery();

   const onError = () => {
      setError(true);
   }

   return (
      <ResetPasswordContainer>
         <LogoContainer>
            <Logo src={LogoWide} />
         </LogoContainer>
         <PageTitle>Reset Password</PageTitle>
         {
            error && (
               <Alert>Sorry, something went wrong, please try again later</Alert>
            )
         }
         <PageCard shadow>
            <ResetPasswordForm onSuccess={onSuccess} onError={onError} token={token} />
         </PageCard>
      </ResetPasswordContainer>
   )
};

export default ResetPassword;
