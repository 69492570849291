import styled from 'styled-components';

const CardInner = styled.div`
   padding: 15px 30px;
   border-bottom: 1px solid var(--light-grey);
   :last-child {
      border-bottom: none;
   }
`;

export default CardInner;
