import React, { useState } from 'react';
import styled from 'styled-components';

import { Link, PageTitle, Alert } from 'atoms';
import { LogoContainer, Logo, CardInner, PageCard, BottomText } from 'apps/Auth/atoms';

import LogoWide from 'assets/icons/podinbox_logo_wide.png'

import ForgotPasswordForm from 'apps/Auth/components/ForgotPasswordForm';

const ForgotPasswordContainer = styled.div`
`;

const ForgotPasswordCard = styled(PageCard)`
   margin-bottom: 40px;
`;

const ForgotPassword = () => {

   const [success, setSuccess] = useState(null);
   const [error, setError] = useState(null);

   const onSend = ({
      email
   }) => {
      setError(false);
      setSuccess('Please check your email to reset your password');
   };

   const onError = ({ message }) => {
      setSuccess(null);
      setError(message);
   }

   return (
      <ForgotPasswordContainer>
         <LogoContainer>
            <Logo src={LogoWide} />
         </LogoContainer>
         <PageTitle>Reset Password</PageTitle>
         {
            success && (
               <Alert type={'success'} message={success} card />
            )
         }
         {
            error && (
               <Alert type={'error'} message={error} card />
            )
         }
         <ForgotPasswordCard shadow>
            <CardInner>
               <ForgotPasswordForm onSend={onSend} onError={onError} />
            </CardInner>
         </ForgotPasswordCard>
         <BottomText>
            <Link to={'/login'} primary>Login</Link> or <Link to={'/signup'} primary>Sign Up</Link>
         </BottomText>
      </ForgotPasswordContainer>
   )
};

export default ForgotPassword;
